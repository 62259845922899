<template>
  <div class="interrogacao">
    <transition name="fade">
      <div v-if="mostrar" class="caixa-info">
        <a 
          href="https://meunorden.com/politica-de-privacidade" 
          target="_blank"
        >Política de Privacidade</a>
        <a 
          href="https://meunorden.com/termos-de-uso" 
          target="_blank"
        >Termos de Uso</a>
      </div>
    </transition>
    <p @click.prevent="mostrarInfos" >?</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mostrar: false
    }
  },
  methods: {
    mostrarInfos() {
      this.mostrar = !this.mostrar
    }
  }
}
</script>

<style>
/* - - - - - - - - - - - - - - - - - - - - - -
 *                                            |
 *                 .CLASSES                   |
 *                                            |
 - - - - - - - - - - - - - - - - - - - - - - */
.caixa-info {
  display: flex;
  flex-direction: column;
  background-color: #E4E4E4;
  box-shadow: 2px 3px 5px #D5D5D2;
  border-radius: 10px;
  width: 214px;
  height: 55px;
  position: absolute;
  bottom: 40px;
  padding: 18px;
  font-size: 16px;
}

.caixa-info a {
  width: fit-content;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.caixa-info a {
  float: left;
  text-decoration: none;
}

.caixa-info a:first-child {
  margin-bottom: 18px;
}

.caixa-info a:visited,
.caixa-info a:active {
  color: black;
}

.interrogacao {
  position: absolute;
  bottom: 31px;
  left: 120px;
  background-color: #B4B4B3;
  box-shadow: 2px 2px 5px #D5D5D2;
  border-radius: 50%;
  text-align: center;
  width: 32px;
  height: 32px;
}

.interrogacao p {
  font-size: 24px;
  font-weight: bold;
  color: #7F7F7F;
  margin-top: 1px;
}

.interrogacao:hover,
.interrogacao p:hover {
  cursor: pointer;
}

/* - - - - - - - - - - - - - - - - - - - - - -
 *                                            |
 *                 @MEDIAS                    |
 *                                            |
 - - - - - - - - - - - - - - - - - - - - - - */

@media screen 
  and (max-width: 320px) {
  
  .interrogacao {
    left: 16px;
    bottom: 22px;
  }
}

@media screen 
  and (min-width: 321px)
  and (max-width: 375px) {
  
  .interrogacao {
    left: 16px;
    bottom: 22px;
  }
}

@media screen 
  and (min-width: 376px)
  and (max-width: 425px) {
  
  .interrogacao {
    left: 16px;
    bottom: 22px;
  }
}

@media screen 
  and (min-width: 426px)
  and (max-width: 768px) {

  .interrogacao {
    left: 16px;
    bottom: 22px;
  }
}

@media screen 
  and (min-width: 769px)
  and (max-width: 1100px)
  and (orientation : landscape) {

  .interrogacao {
    left: 16px;
    bottom: 22px;
  }
}
</style>