import { createApp } from "vue";
import { createHead } from "@vueuse/head"; // Documentação: https://github.com/vueuse/head
import App from "./App.vue";

// Para testes
// localhost:8080?sessao=vk48u033fa69li9e
// telemedicina.nordenhospital.com?sessao=vk48u033fa69li9e

const vueApp = createApp(App);
const head = createHead();

vueApp.use(head);

vueApp.mount("#app");
